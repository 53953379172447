import { RootStore } from "src/stores/RootStore";
import { ConfigureOrganizationStore } from "src/components/ConfigureOrganization/ConfigureOrganizationStore";
import { ConnectableServiceInfoDto, Organization, OrganizationInfo } from "src/api";
import { action, computed, observable } from "mobx";

export class OrganizationConfigureStore extends ConfigureOrganizationStore {
    constructor(private readonly root: RootStore) {
        super();
    }

    protected async update(organization: { id: string; title: string; info: OrganizationInfo }): Promise<void> {
        await this.root.userRpc.organization.update(organization.id, organization.title, organization.info);
        await this.root.organizationStore.load({ oid: organization.id });
    }
}

type OrganizationRouteArgs = {
    oid: string;
};

export class OrganizationStore {
    @observable hasBookKeeperConnections: boolean = false;
    @observable organization?: Organization;
    @observable connectableServices: ConnectableServiceInfoDto[] = [];
    @observable configure: OrganizationConfigureStore;
    @observable args?: OrganizationRouteArgs;

    constructor(private readonly root: RootStore) {
        this.configure = new OrganizationConfigureStore(root);
    }

    @computed get oid(): string {
        return this.organization?.id ?? "";
    }

    @action
    async load(args: OrganizationRouteArgs): Promise<void> {
        this.args = args;
        this.organization = await this.root.userRpc.organization.get(args.oid);
        this.connectableServices = await this.root.userRpc.organizationConnectableService.getServices(args.oid);
        this.hasBookKeeperConnections = await this.root.userRpc.organization.hasBookKeeperConnections(args.oid);
        this.configure.setFields(this.organization!);
    }
}
