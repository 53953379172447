import { LocaleDefinition } from "src/locales/locales";

const en: LocaleDefinition = {
    //Header
    header__logo__title: "Personal Area",
    header__logo__subtitle: "Authorization required",
    header__button__logout: "Logout",
    //Footer
    footer__logo__title: "Personal Area",
    footer__logo__subtitle: "Authorization required",
    //Login Page
    loginPage__button__login: "Log in to your Personal Area",
    loginPage__title: "Personal Area",
    loginPage__subtitle: "Please, login in order to use our services.",
    //User Accounting Account Edit
    accountingAccountEdit__title: "Account",
    accountingAccountEdit__description: "Account inspection.",
    accountingAccountEdit__breadcrumbs__title: "Account",
    //User Accounting Account List
    accountingAccountList__title: "Accounts of «{name}»",
    accountingAccountList__description: "Economic entity account inspection.",
    accountingAccountList__breadcrumbs__title: "Accounts",
    //User Accounting Contract Edit
    accountingContractEdit__title: "Contract",
    accountingContractEdit__description: "Contract inspection.",
    accountingContractEdit__breadcrumbs__title: "Contract",
    //User Accounting Contract List
    accountingContractList__title: "Contracts of «{name}»",
    accountingContractList__description: "Economic entity contract inspection.",
    accountingContractList__breadcrumbs__title: "Contracts",
    //User Accounting Service Edit
    accountingServiceEdit__title: "Service",
    accountingServiceEdit__description: "Service inspection.",
    accountingServiceEdit__breadcrumbs__title: "Service",
    //User Accounting Service List
    accountingServiceList__title: "Services",
    accountingServiceList__description: "Services list inspection.",
    accountingServiceList__breadcrumbs__title: "Services",
    //User Accounting Service Provision List
    accountingServiceProvisionList__title: "Service Provisions for «{name}»",
    accountingServiceProvisionList__description: "Service provision list inspection.",
    accountingServiceProvisionList__breadcrumbs__title: "Service provisions",
    //User Accounting Sub Account Edit
    accountingSubAccountEdit__title: "Sub account",
    accountingSubAccountEdit__description: "Sub account inspection.",
    accountingSubAccountEdit__breadcrumbs__title: "Sub account",
    accountingSubAccountEdit__editor__placeholder: "Sub account Balance",
    //User Accounting Sub Account List
    accountingSubAccountList__title: "Sub accounts of «{name}»",
    accountingSubAccountList__description: "Sub accounts inspection.",
    accountingSubAccountList__breadcrumbs__title: "Sub accounts",
    accountingSubAccountList__links__title: "Sub accounts",
    //User Accounting Transaction Edit
    accountingTransactionEdit__title: "Transaction",
    accountingTransactionEdit__description: "Transaction inspection.",
    accountingTransactionEdit__breadcrumbs__title: "Transaction",
    //User Accounting Transaction List
    accountingTransactionList__title: "Transactions of «{name}»",
    accountingTransactionList__description: "Transactions list for the sub account.",
    accountingTransactionList__template__placeholder: "Transaction type",
    accountingTransactionList__breadcrumbs__title: "Transactions",
    accountingTransactionList__links__title: "Transactions",
    //User App Page
    userApp__button__canConnectTrue: "Connect a service",
    userApp__button__canConnectFalse: "Connection not available",
    userApp__button__reload: "Try again",
    userApp__title__connect: "Service {name} is not connected",
    userApp__title__errorOccured: "Error occured while trying to connect service {name}",
    //User Document List
    documentList__breadcrumbs: "Documents",
    documentList__title: "Documents",
    documentList__description__1: "Found {count} document",
    documentList__description__2: "Found {count} documents",
    documentList__description__3: "Found {count} documents",
    //User Login Redirect
    userLoginRedirect__title: "Completing authorization...",
    userLoginRedirect__subtitle: "Please, wait a few seconds...",
    userLoginRedirect__loading: "We are processing the response, please be patient",
    //User Organization List
    organizationList__breadcrumbs: "Organizations",
    organizationList__title: "Organizations",
    organizationList__description__1: "Found {count} organization",
    organizationList__description__2: "Found {count} organizations",
    organizationList__description__3: "Found {count} organizations",
    organizationList__switch__left: "Organizations",
    organizationList__switch__right: "Add organization",
    //User Support Page
    userSupport__breadcrumbs: "Support",
    userSupport__title: "Support Service",
    userSupport__description: "Instant messaging with support service.",
    //Project Account Add
    projectAccountAdd__title: "Add Account",
    projectAccountAdd__description: "Create a new account.",
    projectAccountAdd__breadcrumbs: "Add Account",
    //Project Account Edit
    projectAccountEdit__title: "Edit Account",
    projectAccountEdit__description: "Edit existing account.",
    projectAccountEdit__breadcrumbs: "Account",
    //Project Account List
    projectAccountList__title: "Accounts of {name}",
    projectAccountList__description: "Economic entity accounts management.",
    projectAccountList__breadcrumbs: "Accounts",
    projectAccountList__links__title: "Accounts",
    //Project Connect Page
    projectConnectPage__title: "Connecting...",
    projectConnectPage__description: "Please be patient, we are trying to reconnect to the remote panel instance.",
    //Project Contract Editor
    projectContractEditor__name__placeholder: "Contract title",
    projectContractEditor__issuerAccounts__placeholder: "Issuer account",
    projectContractEditor__issuerSubAccounts__placeholder: "Issuer sub account",
    projectContractEditor__partners__placeholder: "Partner account",
    projectContractEditor__partnerSubAccounts__placeholder: "Partner sub account",
    //Project Contract Add
    projectContractAdd__title: "Add Contract for «{name}»",
    projectContractAdd__description: "New contract creation.",
    projectContractAdd__breadcrumbs: "Add contract",
    //Project Contract Tariff List View
    projectContractTariffListView__title: "Contract Tariffs",
    projectContractTariffListView__description: "Tariffs list for this particular contract.",
    projectContractTariffListView__thead__first: "Title",
    projectContractTariffListView__thead__second: "Information",
    projectContractTariffListView__tariffs__placeholder: "Please, enter contract title",
    projectContractTariffListView__attachTariff: "Attach tariff to contract",
    //Project Contract Edit
    projectContractEdit__title: "Edit Contract",
    projectContractEdit__description: "Update information about the contract.",
    projectContractEdit__breadcrumbs: "Contract",
    //Project Contract List
    projectContractList__title: "Contracts for «{name}»",
    projectContractList__description: "Contracts for the selected economic entity.",
    projectContractList__breadcrumbs: "Contracts",
    projectContractList__links__title: "Contracts",
    //Project Currency Add
    projectCurrencyAdd__title: "Add Currency",
    projectCurrencyAdd__description: "Create new currency.",
    projectCurrencyAdd__breadcrumbs: "Add currency",
    //Project Currency Edit
    projectCurrencyEdit__title: "Edit Currency",
    projectCurrencyEdit__description: "Edit information about the currency.",
    projectCurrencyEdit__breadcrumbs: "Currency",
    //Project Currency List
    projectCurrencyList__title: "Currencies",
    projectCurrencyList__description: "Available currencies list.",
    projectCurrencyList__breadcrumbs: "Currencies",
    //Project Economic Entity Add
    projectEconomicEntityAdd__title: "Add Partner",
    projectEconomicEntityAdd__description: "Create new partner economic entity.",
    projectEconomicEntityAdd__breadcrumbs: "Add Partner",
    //Project Economic Entity Edit
    projectEconomicEntityEdit__title: "Edit Partner",
    projectEconomicEntityEdit__description: "Edit information about the partner.",
    projectEconomicEntityEdit__breadcrumbs: "Partner",
    //Project Economic Entity List
    projectEconomicEntityList__title: "Partners",
    projectEconomicEntityList__description: "Available partner economic entity list.",
    projectEconomicEntityList__breadcrumbs: "Partners",
    //Project Service Add
    projectServiceAdd__title: "Add Service",
    projectServiceAdd__description: "Create a new service.",
    projectServiceAdd__breadcrumbs: "Add service",
    //Project Service Edit
    projectServiceEdit__title: "Edit Service",
    projectServiceEdit__description: "Edit information about the service.",
    projectServiceEdit__breadcrumbs: "Service",
    //Project Service List
    projectServiceList__title: "Services",
    projectServiceList__description: "Available services list.",
    projectServiceList__breadcrumbs: "Services",
    //Project Service Provision Editor
    projectServiceProvisionEditor__contracts__placeholder: "Contract",
    projectServiceProvisionEditor__services__placeholder: "Service",
    projectServiceProvisionEditor__name__placeholder: "Title",
    //Project Service Provision Add
    projectServiceProvisionAdd__title: "Service Provision",
    projectServiceProvisionAdd__description: "New service provision.",
    projectServiceProvisionAdd__breadcrumbs: "New provision",
    //Project Service Provision List
    projectServiceProvisionList__title: "Service Provisions for «{name}»",
    projectServiceProvisionList__description: "Service provision list.",
    projectServiceProvisionList__breadcrumbs: "Service provisions",
    projectServiceProvisionList__links__title: "Service Provision",
    //Project Sub Account Add
    projectSubAccountAdd__title: "Add sub account",
    projectSubAccountAdd__description: "Create a new sub account for economic entity.",
    projectSubAccountAdd__breadcrumbs: "Add sub account",
    //Project Sub Account Editor
    projectSubAccountEditor__balance__placeholder: "Sub account balance",
    //Project Sub Account Edit
    projectSubAccountEdit__title: "Edit Sub account",
    projectSubAccountEdit__description: "Edit the selected sub account.",
    projectSubAccountEdit__breadcrumbs: "Sub account",
    //Project Sub Account List
    projectSubAccountList__title: "Sub accounts for «{name}»",
    projectSubAccountList__description: "Sub accounts list for the selected account.",
    projectSubAccountList__breadcrumbs: "Sub accounts",
    projectSubAccountList__links__title: "Sub accounts",
    //Project Tariff Add
    projectTariffAdd__title: "Add Tariff",
    projectTariffAdd__description: "Create a new tariff.",
    projectTariffAdd__breadcrumbs: "Add Tariff",
    //Project Tariff Version List View
    projectTariffVersionListView__title: "Tariff Versions",
    projectTariffVersionListView__description: "Version list for the selected tariff.",
    projectTariffVersionListView__thead__version: "Version",
    projectTariffVersionListView__thead__createdAt: "Created at",
    projectTariffVersionListView__thead__validSince: "Available from",
    projectTariffVersionListView__thead__validUntil: "Available to",
    projectTariffVersionListView__goToAdd: "Create",
    //Project Tariff Edit
    projectTariffEdit__title: "Edit Tariff",
    projectTariffEdit__description: "Edit information about the tariff.",
    projectTariffEdit__breadcrumbs: "Tariff",
    //Project Tariff List
    projectTariffList__title: "Tariffs",
    projectTariffList__description: "The list of your tariffs.",
    projectTariffList__breadcrumbs: "Tariffs",
    //Project Tariff Version Editor
    projectTariffVersionEditor__validSince__placeholder: "Start Date",
    projectTariffVersionEditor__validUntil__placeholder: "Invalidation Date",
    //Project Tariff Version Add
    projectTariffVersionAdd__title: "Add Version",
    projectTariffVersionAdd__description: "Create a new tariff version.",
    projectTariffVersionAdd__breadcrumbs: "Add Version",
    //Project Tariff Rate List View
    projectTariffRateListView__title: "Service Pricing",
    projectTariffRateListView__description: "The list of service pricing instances configured on the platform.",
    projectTariffRateListView__serviceName: "Service",
    projectTariffRateListView__currencyName: "Currency",
    projectTariffRateListView__amount: "Price",
    projectTariffRateListView__type: "Type",
    projectTariffRateListView__provision: "Value",
    //Organization Attach Service To Tariff View
    organizationAttachServiceToTariffView__title: "Connect a service",
    organizationAttachServiceToTariffView__description: "The connection of a service with the current tariff.",
    organizationAttachServiceToTariffView__service__placeholder: "Provided service",
    organizationAttachServiceToTariffView__currency__placeholder: "Currency",
    organizationAttachServiceToTariffView__provisionType__placeholder: "Service provision method",
    organizationAttachServiceToTariffView__amount__placeholder: "Amount",
    organizationAttachServiceToTariffView__schedule__placeholder: "Provision Schedule",
    organizationAttachServiceToTariffView__timestamp__placeholder: "Provision Date",
    organizationAttachServiceToTariffView__attachService: "Attach Service to Tariff",
    //Project Tariff Version Edit
    projectTariffVersionEdit__title: "Version Edit",
    projectTariffVersionEdit__description: "Edit information about tariff version.",
    projectTariffVersionEdit__breadcrumbs: "Version",
    //Project Transaction List
    projectTransactionList__title: "Transactions of «{name}»",
    projectTransactionList__description: "The list of transactions of the selected economic entity sub account.",
    projectTransactionList__transactionTypes__placeholder: "Transaction type",
    projectTransactionList__breadcrumbs: "Transactions",
    projectTransactionList__links__title: "Transactions",
    //Project Transaction Editor
    projectTransactionEditor__id__placeholder: "Identifier",
    projectTransactionEditor__name__placeholder: "Title",
    projectTransactionEditor__createdAt__placeholder: "Creation date",
    projectTransactionEditor__type__placeholder: "Type",
    projectTransactionEditor__value__placeholder: "Value",
    //Project Transaction
    projectTransaction__title: "Transaction",
    projectTransaction__description: "View the information about the transaction.",
    projectTransaction__breadcrumbs: "Transaction",
    //Anon Not Found Page
    anonNotFoundPage__title: "Unknown page",
    anonNotFoundPage__subtitle: "Looks like you got lost!",
    anonNotFoundPage__annotation: "You can safely return to the main page.",
    anonNotFoundPage__button__return: "Return to the main page",
    //Admin Organization Edit
    adminOrganizationEdit__title: "Edit Organization",
    adminOrganizationEdit__description: "Edit the information about the organization.",
    adminOrganizationEdit__breadcrumbs: "Organization",
    adminOrganizationEdit__connectedServices: "Connected services",
    adminOrganizationEdit__connectedServicesEnterTitle: "Please, enter service name...",
    //Admin Organization List
    adminOrganizationList__title: "Organizations",
    adminOrganizationList__description: "The list of all organizations created in system for current user.",
    adminOrganizationList__breadcrumbs: "Organizations",
    //Admin User Edit
    adminUserEdit__title: "User Edit",
    adminUserEdit__description: "Edit user profile",
    adminUserEdit__breadcrumbs: "User",
    adminUserEdit__connectedServices: "Connected services",
    adminUserEdit__connectedServicesEnterTitle: "Please, enter service name...",
    //Admin User List
    adminUserList__title: "Users",
    adminUserList__description: "The list of all users created on the platform.",
    adminUserList__breadcrumbs: "Users",
    // Organization Page
    organizationPage__title: "General Information",
    organizationPage__description: "General information about the organization.",
    organizationPage__breadcrumbs: "General Info",
    //Organization App
    organizationApp__showActivationScreen__title: "Service {name} is not connected",
    organizationApp__showActivationScreen__canConnect__true: "Connect the service",
    organizationApp__showActivationScreen__canConnect__false: "Connection is not available",
    organizationApp__errorOccured__title: "Error occured while trying to load service {name}",
    organizationApp__errorOccured__reload: "Try again",
    //Organization Document List
    organizationDocumentList__title: "Organization Documents",
    organizationDocumentList__description: "The list of documents belonging to the organization.",
    organizationDocumentList__breadcrumbs: "Organization Documents",
    //Organization Project Add
    organizationProjectAdd__title: "Add Project",
    organizationProjectAdd__description: "Create a new project.",
    organizationProjectAdd__breadcrumbs: "Add Project",
    //Organization Project Edit
    organizationProjectEdit__title: "Edit Project",
    organizationProjectEdit__description: "Edit information about the project.",
    organizationProjectEdit__breadcrumbs: "Project",
    //Organization Project List
    organizationProjectList__title: "Projects",
    organizationProjectList__description: "The list of all projects available for this organization.",
    organizationProjectList__button: "Open control panel",
    organizationProjectList__breadcrumbs: "Projects",
    //Organization Support
    organizationSupport__title: "Support Service",
    organizationSupport__description: "The uploaded documents are sent to this chat as well.",
    organizationSupport__breadcrumbs: "Support Service",
    //Com Organization Block
    comOrganizationBlock__button__login: "Organization control panel",
    comOrganizationBlock__button__remove: "Remove organization",
    //Com Configure Organization
    comConfigureOrganization__title__placeholder: "Organization title",
    comConfigureOrganization__inn__placeholder: "INN",
    comConfigureOrganization__ogrn__placeholder: "OGRN",
    comConfigureOrganization__kpp__placeholder: "KPP",
    comConfigureOrganization__address__placeholder: "Legal Address",
    comConfigureOrganization__bankInfo__placeholder: "Bank Info",
    comConfigureOrganization__save: "Save organization",
    //Com Success Message Box
    comSuccessMessageBox__header: "Done!",
    comSuccessMessageBox__message: "The changes were successfully saved.",
    //Com Confirm Delete Message Box
    comConfirmDeleteMessageBox__header: "Are you sure?",
    comConfirmDeleteMessageBox__messages1: "You are going to delete this item!",
    comConfirmDeleteMessageBox__messages2: "This action is irreversible.",
    comConfirmDeleteMessageBox__confirmText: "Yes, delete",
    comConfirmDeleteMessageBox__discardText: "No, do not delete",
    //Com Content Editor Add Page
    comContentEditorAddPage__button__add: "Add",
    comContentEditorAddPage__button__goToItems: "Return back to the list",
    //Com Content Editor Edit Page
    comContentEditorEditPage__button__save: "Save",
    comContentEditorEditPage__button__removeSelectedItem: "Delete",
    comContentEditorEditPage__button__goToItems: "Return back to the list",
    //Com Content Editor List Page
    comContentEditorListPage__thead__leftTitle: "Title",
    comContentEditorListPage__thead__rightTitle: "Information",
    comContentEditorListPage__totalPages_0: "Nothing found.",
    comContentEditorListPage__button__goToAdd: "Add",
    //Com Content Editor Search
    comContentEditorSearch__summary: "Found {totalItemsCount} items, total page count is {totalPages}.",
    //Com MessageBox
    comMessageBox__button__onConfirm: "Confirm",
    comMessageBox__button__onDiscard: "Close",
    //Com Document Block
    comDocumentBlock__title: "Upload",
    comDocumentBlock__description: "Document uploaded at: {createdAt}.",
    //Com Document Upload Block
    comDocumentUploadBlock__heading: "Upload document",
    comDocumentUploadBlock__description: "Drag and drop the document into the area below.",
    comDocumentUploadBlock__file__placeholder: "Document",
    comDocumentUploadBlock__title__placeholder: "Document title",
    comDocumentUploadBlock__button__upload: "Upload document",
    //Com Document Search Block
    comDocumentSearchBlock__heading: "Find a document",
    comDocumentSearchBlock__description: "Enter the search query...",
    comDocumentSearchBlock__query__placeholder: "Document title",
    comDocumentSearchBlock__button__search: "Find documents",
    comDocumentSearchBlock__button__resetFilters: "Reset filters",
    //Com Document List
    comDocumentList__nothingFoundLabel: "No documents uploaded yet.",
    //Com Embeddable App View
    comEmbeddableAppView__button__reload: "Refresh the page",
    comEmbeddableAppView__error: "The service is temporary unavailable.",
    //Com Preview Box
    comPreviewBox__hide__header: "View the image",
    comPreviewBox__hide__footer: "Close",
    //Com Email
    comEmail__alert: "We will send the confirmation email to this address.",
    //Com Mobile
    comMobile__alert: "We will send an SMS with a confirmation code to this phone.",
    //Com Confirmation Code Input
    comConfirmationCodeInput__labelText: "Received code",
    //Com Password
    comPassword__password__placeholder: "Password",
    comPassword__password__labelText: "Please, enter your password.",
    comPassword__passwordRepeat__placeholder: "Repeat password",
    comPassword__passwordRepeat__labelText: "Please, repeat your password.",
    //Com Admin Navigation
    comAdminNavigation__LocalizedNavigationDelimiter__control: "Management",
    comAdminNavigation__RouterLink__organizationList: "Organizations",
    comAdminNavigation__RouterLink__userList: "Users",
    comAdminNavigation__LocalizedNavigationDelimiter__admin: "Admin Control Panel",
    comAdminNavigation__RouterLink__logout: "Logout",
    comAdminNavigation__RouterLink__bookKeeperConnectionList: "Billing Instances",
    comAdminNavigation__RouterLink__organizationBookKeeperConnectionList: "Billing Connections",
    comAdminNavigation__RouterLink__uPanelApiConnectionList: "UPanel Instances",
    comAdminNavigation__RouterLink__connectableServiceList: "Services",
    comAdminNavigation__LocalizedNavigationDelimiter__services: "Service Management",
    comAdminNavigation__LocalizedNavigationDelimiter__upanel: "UPanel Management",
    comAdminNavigation__LocalizedNavigationDelimiter__billings: "Billing Management",
    //Com Admin BookKeeper Connection
    adminBookKeeperConnectionAdd__title: "New Billing Instance",
    adminBookKeeperConnectionAdd__description: "Add a new billing instance.",
    bookKeeperConnectionList__breadcrumbs: "Billing Instances",
    bookKeeperConnectionAdd__breadcrumbs: "New Billing Instance",
    bookKeeperConnectionEdit__breadcrumbs: "Billing Instance",
    adminBookKeeperConnectionEdit__title: "Edit Billing Instance",
    adminBookKeeperConnectionEdit__description: "Edit the connection settings to the selected billing instance.",
    adminBookKeeperConnectionList__title: "Billing Instances",
    adminBookKeeperConnectionList__description: "All billing instances created in the system.",
    //Com Admin Conn Serv
    adminConnectableServiceAdd__title: "New Service",
    adminConnectableServiceAdd__description: "Add a new connectable service.",
    connectableServiceList__breadcrumbs: "Services",
    connectableServiceAdd__breadcrumbs: "New Service",
    connectableServiceEdit__breadcrumbs: "Service",
    adminConnectableServiceEdit__title: "Edit Service",
    adminConnectableServiceEdit__description: "Edit the connectable service information.",
    adminConnectableServiceList__title: "Services List",
    adminConnectableServiceList__description: "The list of all connectable services created in the system.",
    adminConnectableServiceList__available: "Available",
    adminConnectableServiceList__unavailable: "Unavailable",
    adminConnectableServiceList__visible: "Visible",
    adminConnectableServiceList__hidden: "Hidden",
    //Com Admin OBKC
    adminOrganizationBookKeeperConnectionAdd__title: "New Connection",
    adminOrganizationBookKeeperConnectionAdd__description: "Add a new organization connection to billing instance.",
    organizationBookKeeperConnectionList__breadcrumbs: "Connections",
    organizationBookKeeperConnectionAdd__breadcrumbs: "New connection",
    organizationBookKeeperConnectionEdit__breadcrumbs: "Connection",
    adminOrganizationBookKeeperConnectionEdit__title: "Edit Connection",
    adminOrganizationBookKeeperConnectionEdit__description: "Edit the organization connection to a billing instance.",
    adminOrganizationBookKeeperConnectionList__title: "Connections to billing instances",
    adminOrganizationBookKeeperConnectionList__description:
        "The list of all connections to billing instances created in the system.",
    //Com Admin UPANEL API Conn
    adminUPanelApiConnectionAdd__title: "New Panel",
    adminUPanelApiConnectionAdd__description: "Add a new universal panel connection.",
    uPanelApiConnectionList__breadcrumbs: "Panels",
    uPanelApiConnectionAdd__breadcrumbs: "New Panel",
    uPanelApiConnectionEdit__breadcrumbs: "Panel",
    adminUPanelApiConnectionEdit__title: "Edit Universal Panel",
    adminUPanelApiConnectionEdit__description: "Edit connection settings to the universal panel.",
    adminUPanelApiConnectionList__title: "Universal Panels",
    adminUPanelApiConnectionList__description: "The list of all universal panels connections created.",
    //Com Admin Projects
    adminProjectList__breadcrumbs: "Projects",
    adminProjectAdd__breadcrumbs: "New Project",
    adminProjectEdit__breadcrumbs: "Project",
    adminProjectAdd__title: "New Project",
    adminProjectAdd__description: "Add a new project for this organization.",
    adminProjectEdit__title: "Project",
    adminProjectEdit__description: "Edit the project of the selected organization.",
    adminProjectList__title: "All Projects",
    adminProjectList__description: "The list of all projects belonging to this organization.",
    //Com Admin Shell
    comAdminShell__title: "Unnamed",
    comAdminShell__description: "Admin",
    //Com Org Navigation
    comOrgNavigation__LocalizedNavigationDelimiter__urProfile: "Legal Entity",
    comOrgNavigation__RouterLink__organization: "General Information",
    comOrgNavigation__RouterLink__organizationDocuments: "Documents",
    comOrgNavigation__RouterLink__organizationSupport: "Support Service",
    comOrgNavigation__LocalizedNavigationDelimiter__services: "Services",
    comOrgNavigation__LocalizedNavigationDelimiter__project: "Projects",
    comOrgNavigation__RouterLink__projectList: "Projects",
    comOrgNavigation__RouterLink__logout: "Logout",
    comOrgNavigation__LocalizedNavigationDelimiter__organization: "Organization control panel",
    comOrgNavigation__LocalizedNavigationDelimiter__buh: "Accounting",
    comOrgNavigation__RouterLink__contractList: "Contracts",
    comOrgNavigation__RouterLink__accountList: "Accounts",
    comOrgNavigation__RouterLink__serviceProvisionList: "Service Provision",
    comOrgNavigation__RouterLink__serviceList: "Services",
    //Com Org Shell
    comOrgShell__description: "Legal Entity",
    //Com Client Navigation
    comClientNavigation__LocalizedNavigationDelimiter__ecoEntity: "Economic Entity",
    comClientNavigation__RouterLink__contractList: "Contracts",
    comClientNavigation__RouterLink__accountList: "Accounts",
    comClientNavigation__RouterLink__serviceProvisionList: "Service Provision",
    comClientNavigation__LocalizedNavigationDelimiter__billing: "Billing",
    comClientNavigation__RouterLink__economicEntityList: "Partners",
    comClientNavigation__RouterLink__serviceList: "Services",
    comClientNavigation__RouterLink__tariffList: "Tariffs",
    comClientNavigation__RouterLink__cabinet: "UPanel",
    comClientNavigation__RouterLink__currencyList: "Currencies",
    comClientNavigation__LocalizedNavigationDelimiter__client: "Control Panel",
    comClientNavigation__RouterLink__logout: "Logout",
    //Com Project Shell
    comProjectShell__title: "Project",
    //Com`User Navigation
    comUserNavigation__LocalizedNavigationDelimiter__fiz: "Individual",
    comUserNavigation__RouterLink__organizations: "Organizations",
    comUserNavigation__RouterLink__documents: "Documents",
    comUserNavigation__RouterLink__support: "Support service",
    comUserNavigation__LocalizedNavigationDelimiter__buh: "Accounting",
    comUserNavigation__RouterLink__userAccountingContractList: "Contracts",
    comUserNavigation__RouterLink__userAccountingAccountList: "Accounts",
    comUserNavigation__RouterLink__userAccountingServiceProvisionList: "Service provision",
    comUserNavigation__RouterLink__userAccountingServiceList: "Services",
    comUserNavigation__LocalizedNavigationDelimiter__services: "Services",
    comUserNavigation__LocalizedNavigationDelimiter__cab: "Control Panel",
    comUserNavigation__RouterLink__logout: "Logout",
    //Com User Shell
    comUserShell__title: "Unnamed",
    comUserShell__description: "Individual",
    //Com Support View
    comSupportView__more: "Load more...",
    comSupportView__send: "Send message",
    //Com Validated File Input
    comValidatedFileInput__existingFileUrl: "View the file uploaded earlier",
    comValidatedFileInput__fileRemoveRequest: "Remove the file uploaded earlier",
    comValidatedFileInput__isFileRemovalRequested: "The file will be removed after saving the changes.",
    //Error Messages
    error__isNotEmpty: "Required.",
    error__isNotEmpty__search: "The search query can't be empty.",
    error__isNotEmpty__support: "The message can't be empty.",
    error__isNotEmpty__services: "The field can't be empty.",
    //ETC
    untitled: "Unnamed",
    searchLoading: "Search...",
    loadLoading: "Loading...",
    fallback: "Please wait...",
    undefined: "Nothing available here",
    logout: "Logout",
    urProfile: "Legal Entity",
    fizProfile: "Individual",
    projectOrg: "Project",
    unbroken: "Continuous",
    transactional: "Transactional",
    incoming: "Incoming",
    outgoing: "Outgoing",
    transfer: "Transfer",
    assignTitle: "Title",
    assignInfo: "Info",
    assignDiscard: "Remove assignment",
    assignAssign: "Confirm and assign",
};

export default en;
