const ru = {
    //Header
    header__logo__title: "Личный кабинет",
    header__logo__subtitle: "Управление услугами",
    header__button__logout: "ВЫЙТИ ИЗ СИСТЕМЫ",
    //Footer
    footer__logo__title: "Личный кабинет",
    footer__logo__subtitle: "Управление услугами",
    //Login Page
    loginPage__button__login: "Войти в личный кабинет",
    loginPage__title: "Личный кабинет",
    loginPage__subtitle: "Пожалуйста, войдите, чтобы воспользоваться сервисом.",
    //User Accounting Account Edit
    accountingAccountEdit__title: "Просмотр счёта",
    accountingAccountEdit__description: "Просмотр счёта",
    accountingAccountEdit__breadcrumbs__title: "Счёт",
    //User Accounting Account List
    accountingAccountList__title: "Счета контрагента «{name}»",
    accountingAccountList__description: "Просмотр счетов контрагента.",
    accountingAccountList__breadcrumbs__title: "Счета",
    //User Accounting Contract Edit
    accountingContractEdit__title: "Просмотр контракта",
    accountingContractEdit__description: "Просмотр информации о контракте.",
    accountingContractEdit__breadcrumbs__title: "Контракт",
    //User Accounting Contract List
    accountingContractList__title: "Контракты «{name}»",
    accountingContractList__description: "Просмотр контрактов.",
    accountingContractList__breadcrumbs__title: "Контракты",
    //User Accounting Service Edit
    accountingServiceEdit__title: "Просмотр услуги",
    accountingServiceEdit__description: "Просмотр информации об услуге.",
    accountingServiceEdit__breadcrumbs__title: "Услуга",
    //User Accounting Service List
    accountingServiceList__title: "Услуги",
    accountingServiceList__description: "Просмотр списка услуг.",
    accountingServiceList__breadcrumbs__title: "Услуги",
    //User Accounting Service Provision List
    accountingServiceProvisionList__title: "Предоставление услуг «{name}»",
    accountingServiceProvisionList__description: "Просмотр списка предоставленных услуг.",
    accountingServiceProvisionList__breadcrumbs__title: "Предоставление услуг",
    //User Accounting Sub Account Edit
    accountingSubAccountEdit__title: "Просмотр субсчёта",
    accountingSubAccountEdit__description: "Просмотр субсчёта контрагента.",
    accountingSubAccountEdit__breadcrumbs__title: "Субсчёт",
    accountingSubAccountEdit__editor__placeholder: "Баланс субсчёта",
    //User Accounting Sub Account List
    accountingSubAccountList__title: "Субсчета счёта «{name}»",
    accountingSubAccountList__description: "Просмотр субсчетов счёта.",
    accountingSubAccountList__breadcrumbs__title: "Субсчета",
    accountingSubAccountList__links__title: "Субсчета",
    //User Accounting Transaction Edit
    accountingTransactionEdit__title: "Просмотр транзакции",
    accountingTransactionEdit__description: "Просмотр транзакции организации.",
    accountingTransactionEdit__breadcrumbs__title: "Транзакция",
    //User Accounting Transaction List
    accountingTransactionList__title: "Транзакции «{name}»",
    accountingTransactionList__description: "Здесь можно посмотреть транзакции субсчёта.",
    accountingTransactionList__template__placeholder: "Тип транзакции",
    accountingTransactionList__breadcrumbs__title: "Транзакции",
    accountingTransactionList__links__title: "Транзакции",
    //User App Page
    userApp__button__canConnectTrue: "Подключить услугу",
    userApp__button__canConnectFalse: "Подключение недоступно",
    userApp__button__reload: "Попробовать снова",
    userApp__title__connect: "Услуга {name} не подключена",
    userApp__title__errorOccured: "При попытке загрузить услугу {name} возникла ошибка",
    //User Document List
    documentList__breadcrumbs: "Мои документы",
    documentList__title: "Мои документы",
    documentList__description__1: "Найден {count} документ",
    documentList__description__2: "Найдено {count} документа",
    documentList__description__3: "Найдено {count} документов",
    //User Login Redirect
    userLoginRedirect__title: "Завершаем авторизацию",
    userLoginRedirect__subtitle: "Пожалуйста, подождите...",
    userLoginRedirect__loading: "Обрабатываем ответ...",
    //User Organization List
    organizationList__breadcrumbs: "Мои организации",
    organizationList__title: "Мои организации",
    organizationList__description__1: "Найден {count} организация",
    organizationList__description__2: "Найдено {count} организации",
    organizationList__description__3: "Найдено {count} организаций",
    organizationList__switch__left: "Список организаций",
    organizationList__switch__right: "Добавить организацию",
    //User Support Page
    userSupport__breadcrumbs: "Служба поддержки",
    userSupport__title: "Служба поддержки",
    userSupport__description: "Обмен сообщениями с поддержкой.",
    //Project Account Add
    projectAccountAdd__title: "Добавление счёта",
    projectAccountAdd__description: "Создание нового счёта.",
    projectAccountAdd__breadcrumbs: "Добавление счёта",
    //Project Account Edit
    projectAccountEdit__title: "Редактирование счёта",
    projectAccountEdit__description: "Редактирование счёта контрагента.",
    projectAccountEdit__breadcrumbs: "Счёт",
    //Project Account List
    projectAccountList__title: "Счета {name}",
    projectAccountList__description: "Управление счетами контрагента.",
    projectAccountList__breadcrumbs: "Счета",
    projectAccountList__links__title: "Счета",
    //Project Connect Page
    projectConnectPage__title: "Подключение к кабинету...",
    projectConnectPage__description: "Пытаемся установить связь с удаленным кабинетом. Пожалуйста, подождите.",
    //Project Contract Editor
    projectContractEditor__name__placeholder: "Название контракта",
    projectContractEditor__issuerAccounts__placeholder: "Счёт эмитента",
    projectContractEditor__issuerSubAccounts__placeholder: "Субсчёт эмитента",
    projectContractEditor__partners__placeholder: "Счёт потребителя",
    projectContractEditor__partnerSubAccounts__placeholder: "Субсчёт потребителя",
    //Project Contract Add
    projectContractAdd__title: "Новый контракт для «{name}»",
    projectContractAdd__description: "Создание нового контракта.",
    projectContractAdd__breadcrumbs: "Новый контракт",
    //Project Contract Tariff List View
    projectContractTariffListView__title: "Тарифы контракта",
    projectContractTariffListView__description: "Список тарифов данного контракта.",
    projectContractTariffListView__thead__first: "Название",
    projectContractTariffListView__thead__second: "Сведения",
    projectContractTariffListView__tariffs__placeholder: "Введите название тарифа",
    projectContractTariffListView__attachTariff: "Привязать тариф к контракту",
    //Project Contract Edit
    projectContractEdit__title: "Редактирование контракта",
    projectContractEdit__description: "Редактирование информации о контракте.",
    projectContractEdit__breadcrumbs: "Контракт",
    //Project Contract List
    projectContractList__title: "Контракты «{name}»",
    projectContractList__description: "Контракты данного контрагента.",
    projectContractList__breadcrumbs: "Контракты",
    projectContractList__links__title: "Контракты",
    //Project Currency Add
    projectCurrencyAdd__title: "Новая валюта",
    projectCurrencyAdd__description: "Создание новой валюты.",
    projectCurrencyAdd__breadcrumbs: "Новая валюта",
    //Project Currency Edit
    projectCurrencyEdit__title: "Редактирование валюты",
    projectCurrencyEdit__description: "Редактирование информации о валюте.",
    projectCurrencyEdit__breadcrumbs: "Валюта",
    //Project Currency List
    projectCurrencyList__title: "Валюты",
    projectCurrencyList__description: "Список ваших валют.",
    projectCurrencyList__breadcrumbs: "Валюты",
    //Project Economic Entity Add
    projectEconomicEntityAdd__title: "Новый контрагент",
    projectEconomicEntityAdd__description: "Создание нового контрагента.",
    projectEconomicEntityAdd__breadcrumbs: "Новый контрагент",
    //Project Economic Entity Edit
    projectEconomicEntityEdit__title: "Редактирование контрагента",
    projectEconomicEntityEdit__description: "Редактирование информации о контрагенте.",
    projectEconomicEntityEdit__breadcrumbs: "Контрагент",
    //Project Economic Entity List
    projectEconomicEntityList__title: "Контрагенты",
    projectEconomicEntityList__description: "Список ваших контрагентов.",
    projectEconomicEntityList__breadcrumbs: "Контрагенты",
    //Project Service Add
    projectServiceAdd__title: "Новая услуга",
    projectServiceAdd__description: "Создание новой услуги.",
    projectServiceAdd__breadcrumbs: "Новая услуга",
    //Project Service Edit
    projectServiceEdit__title: "Редактирование услуги",
    projectServiceEdit__description: "Редактирование информации об услуге.",
    projectServiceEdit__breadcrumbs: "Услуга",
    //Project Service List
    projectServiceList__title: "Услуги",
    projectServiceList__description: "Список ваших услуг.",
    projectServiceList__breadcrumbs: "Услуги",
    //Project Service Provision Editor
    projectServiceProvisionEditor__contracts__placeholder: "Контракт",
    projectServiceProvisionEditor__services__placeholder: "Услуга",
    projectServiceProvisionEditor__name__placeholder: "Название",
    //Project Service Provision Add
    projectServiceProvisionAdd__title: "Предоставление услуг",
    projectServiceProvisionAdd__description: "Новое предоставление услуги.",
    projectServiceProvisionAdd__breadcrumbs: "Новое предоставление",
    //Project Service Provision List
    projectServiceProvisionList__title: "Предоставление услуг «{name}»",
    projectServiceProvisionList__description: "Список предоставленных услуг.",
    projectServiceProvisionList__breadcrumbs: "Предоставление услуг",
    projectServiceProvisionList__links__title: "Предоставление услуг",
    //Project Sub Account Add
    projectSubAccountAdd__title: "Добавление субсчёта",
    projectSubAccountAdd__description: "Добавление субсчёта организации.",
    projectSubAccountAdd__breadcrumbs: "Добавление субсчёта",
    //Project Sub Account Editor
    projectSubAccountEditor__balance__placeholder: "Баланс субсчёта",
    //Project Sub Account Edit
    projectSubAccountEdit__title: "Субсчёт",
    projectSubAccountEdit__description: "Редактирование субсчёта контрагента.",
    projectSubAccountEdit__breadcrumbs: "Субсчёт",
    //Project Sub Account List
    projectSubAccountList__title: "Субсчета счёта «{name}»",
    projectSubAccountList__description: "Здесь можно посмотреть субсчета.",
    projectSubAccountList__breadcrumbs: "Субсчета",
    projectSubAccountList__links__title: "Субсчета",
    //Project Tariff Add
    projectTariffAdd__title: "Новый тариф",
    projectTariffAdd__description: "Создание нового тарифа.",
    projectTariffAdd__breadcrumbs: "Новый тариф",
    //Project Tariff Version List View
    projectTariffVersionListView__title: "Версии тарифа",
    projectTariffVersionListView__description: "Список версий данного тарифа.",
    projectTariffVersionListView__thead__version: "Версия",
    projectTariffVersionListView__thead__createdAt: "Создана",
    projectTariffVersionListView__thead__validSince: "Действует, с",
    projectTariffVersionListView__thead__validUntil: "Действует, по",
    projectTariffVersionListView__goToAdd: "Добавить",
    //Project Tariff Edit
    projectTariffEdit__title: "Редактирование тарифа",
    projectTariffEdit__description: "Редактирование информации о тарифе.",
    projectTariffEdit__breadcrumbs: "Тариф",
    //Project Tariff List
    projectTariffList__title: "Тарифы",
    projectTariffList__description: "Список ваших тарифов.",
    projectTariffList__breadcrumbs: "Тарифы",
    //Project Tariff Version Editor
    projectTariffVersionEditor__validSince__placeholder: "Дата начала",
    projectTariffVersionEditor__validUntil__placeholder: "Дата инвалидации",
    //Project Tariff Version Add
    projectTariffVersionAdd__title: "Новая версия",
    projectTariffVersionAdd__description: "Создание новой версии тарифа.",
    projectTariffVersionAdd__breadcrumbs: "Новая версия",
    //Project Tariff Rate List View
    projectTariffRateListView__title: "Стоимость услуг",
    projectTariffRateListView__description: "Список стоимостей услуг данного тарифа.",
    projectTariffRateListView__serviceName: "Услуга",
    projectTariffRateListView__currencyName: "Валюта",
    projectTariffRateListView__amount: "Цена",
    projectTariffRateListView__type: "Тип",
    projectTariffRateListView__provision: "Значение",
    //Organization Attach Service To Tariff View
    organizationAttachServiceToTariffView__title: "Привязать услугу",
    organizationAttachServiceToTariffView__description: "Соединение услуги с данным тарифом.",
    organizationAttachServiceToTariffView__service__placeholder: "Оказываемая услуга",
    organizationAttachServiceToTariffView__currency__placeholder: "Валюта",
    organizationAttachServiceToTariffView__provisionType__placeholder: "Способ предоставления услуги",
    organizationAttachServiceToTariffView__amount__placeholder: "Сумма",
    organizationAttachServiceToTariffView__schedule__placeholder: "График предоставления",
    organizationAttachServiceToTariffView__timestamp__placeholder: "Дата предоставления",
    organizationAttachServiceToTariffView__attachService: "Привязать услугу к тарифу",
    //Project Tariff Version Edit
    projectTariffVersionEdit__title: "Редактирование версии",
    projectTariffVersionEdit__description: "Редактирование информации о версии тарифа.",
    projectTariffVersionEdit__breadcrumbs: "Версия",
    //Project Transaction List
    projectTransactionList__title: "Транзакции «{name}»",
    projectTransactionList__description: "Здесь можно посмотреть транзакции организации.",
    projectTransactionList__transactionTypes__placeholder: "Тип транзакции",
    projectTransactionList__breadcrumbs: "Транзакции",
    projectTransactionList__links__title: "Транзакции",
    //Project Transaction Editor
    projectTransactionEditor__id__placeholder: "Идентификатор",
    projectTransactionEditor__name__placeholder: "Название",
    projectTransactionEditor__createdAt__placeholder: "Дата создания",
    projectTransactionEditor__type__placeholder: "Тип",
    projectTransactionEditor__value__placeholder: "Значение",
    //Project Transaction
    projectTransaction__title: "Просмотр транзакции",
    projectTransaction__description: "Просмотр транзакции организации.",
    projectTransaction__breadcrumbs: "Транзакция",
    //Anon Not Found Page
    anonNotFoundPage__title: "Неизвестная страница",
    anonNotFoundPage__subtitle: "Похоже, что вы заблудились!",
    anonNotFoundPage__annotation: "Вернуться на главную страницу",
    anonNotFoundPage__button__return: "Вернуться на главную страницу",
    //Admin Organization Edit
    adminOrganizationEdit__title: "Редактирование организации",
    adminOrganizationEdit__description: "Редактирование информации об организации.",
    adminOrganizationEdit__breadcrumbs: "Организация",
    adminOrganizationEdit__connectedServices: "Подключенные услуги",
    adminOrganizationEdit__connectedServicesEnterTitle: "Введите название услуги...",
    //Admin Organization List
    adminOrganizationList__title: "Организации",
    adminOrganizationList__description: "Список всех созданных в системе организаций.",
    adminOrganizationList__breadcrumbs: "Организации",
    //Admin User Edit
    adminUserEdit__title: "Редактирование пользователя",
    adminUserEdit__description: "Редактирование пользователя",
    adminUserEdit__breadcrumbs: "Пользователь",
    adminUserEdit__connectedServices: "Подключенные услуги",
    adminUserEdit__connectedServicesEnterTitle: "Введите название услуги...",
    //Admin User List
    adminUserList__title: "Пользователи",
    adminUserList__description: "Список всех зарегистрированных в системе пользователей.",
    adminUserList__breadcrumbs: "Пользователи",
    // Organization Page
    organizationPage__title: "Общие сведения",
    organizationPage__description: "Здесь можно посмотреть или обновить данные организации.",
    organizationPage__breadcrumbs: "Общие сведения",
    //Organization App
    organizationApp__showActivationScreen__title: "Услуга {name} не подключена",
    organizationApp__showActivationScreen__canConnect__true: "Подключить услугу",
    organizationApp__showActivationScreen__canConnect__false: "Подключение недоступно",
    organizationApp__errorOccured__title: "При попытке загрузить услугу {name} возникла ошибка",
    organizationApp__errorOccured__reload: "Попробовать снова",
    //Organization Document List
    organizationDocumentList__title: "Документы организации",
    organizationDocumentList__description: "Здесь можно посмотреть или загрузить документы организации.",
    organizationDocumentList__breadcrumbs: "Документы организации",
    //Organization Project Add
    organizationProjectAdd__title: "Новый проект",
    organizationProjectAdd__description: "Создание нового проекта.",
    organizationProjectAdd__breadcrumbs: "Новый проект",
    //Organization Project Edit
    organizationProjectEdit__title: "Редактирование проекта",
    organizationProjectEdit__description: "Редактирование информации о проекте.",
    organizationProjectEdit__breadcrumbs: "Проект",
    //Organization Project List
    organizationProjectList__title: "Проекты",
    organizationProjectList__description: "Список всех проектов вашей организации.",
    organizationProjectList__button: "Открыть кабинет проекта",
    organizationProjectList__breadcrumbs: "Проекты",
    //Organization Support
    organizationSupport__title: "Служба поддержки",
    organizationSupport__description: "В данный чат дублируются все загруженные документы.",
    organizationSupport__breadcrumbs: "Служба поддержки",
    //Com Organization Block
    comOrganizationBlock__button__login: "Кабинет организации",
    comOrganizationBlock__button__remove: "Удалить организацию",
    //Com Configure Organization
    comConfigureOrganization__title__placeholder: "Название организации",
    comConfigureOrganization__inn__placeholder: "ИНН организации",
    comConfigureOrganization__ogrn__placeholder: "ОГРН организации",
    comConfigureOrganization__kpp__placeholder: "КПП организации",
    comConfigureOrganization__address__placeholder: "Адрес юридического лица",
    comConfigureOrganization__bankInfo__placeholder: "Банковские реквизиты",
    comConfigureOrganization__save: "Сохранить организацию",
    //Com Success Message Box
    comSuccessMessageBox__header: "Готово",
    comSuccessMessageBox__message: "Изменения сохранены.",
    //Com Confirm Delete Message Box
    comConfirmDeleteMessageBox__header: "Вы уверены?",
    comConfirmDeleteMessageBox__messages1: "Вы собираетесь удалить этот элемент!",
    comConfirmDeleteMessageBox__messages2: "Удалённые данные будет невозможно восстановить.",
    comConfirmDeleteMessageBox__confirmText: "Да, удалить",
    comConfirmDeleteMessageBox__discardText: "Нет, не удалять",
    //Com Content Editor Add Page
    comContentEditorAddPage__button__add: "Добавить",
    comContentEditorAddPage__button__goToItems: "К списку",
    //Com Content Editor Edit Page
    comContentEditorEditPage__button__save: "Сохранить",
    comContentEditorEditPage__button__removeSelectedItem: "Удалить",
    comContentEditorEditPage__button__goToItems: "К списку",
    //Com Content Editor List Page
    comContentEditorListPage__thead__leftTitle: "Название",
    comContentEditorListPage__thead__rightTitle: "Сведения",
    comContentEditorListPage__totalPages_0: "По вашему запросу ничего не найдено.",
    comContentEditorListPage__button__goToAdd: "Добавить",
    //Com Content Editor Search
    comContentEditorSearch__summary: "Нашлось элементов: {totalItemsCount} (количество страниц: {totalPages}).",
    //Com MessageBox
    comMessageBox__button__onConfirm: "Подтвердить",
    comMessageBox__button__onDiscard: "Закрыть",
    //Com Document Block
    comDocumentBlock__title: "Загрузить",
    comDocumentBlock__description: "Документ загружен: {createdAt}.",
    //Com Document Upload Block
    comDocumentUploadBlock__heading: "Загрузить документ",
    comDocumentUploadBlock__description: "Перетащите документ в область ниже.",
    comDocumentUploadBlock__file__placeholder: "Документ",
    comDocumentUploadBlock__title__placeholder: "Название документа",
    comDocumentUploadBlock__button__upload: "Загрузить документ",
    //Com Document Search Block
    comDocumentSearchBlock__heading: "Найти документ",
    comDocumentSearchBlock__description: "Введите поисковый запрос.",
    comDocumentSearchBlock__query__placeholder: "Название документа",
    comDocumentSearchBlock__button__search: "Найти документы",
    comDocumentSearchBlock__button__resetFilters: "Сбросить фильтры",
    //Com Document List
    comDocumentList__nothingFoundLabel: "Пока не загружено ни одного документа.",
    //Com Embeddable App View
    comEmbeddableAppView__button__reload: "Обновить страницу",
    comEmbeddableAppView__error: "Сервис временно недоступен.",
    //Com Preview Box
    comPreviewBox__hide__header: "Просмотр работы",
    comPreviewBox__hide__footer: "Закрыть",
    //Com Email
    comEmail__alert: "На указанный адрес электронной почты будет отправлено письмо с кодом подтверждения.",
    //Com Mobile
    comMobile__alert: "Мы отправим SMS с кодом подтверждения на этот номер телефона.",
    //Com Confirmation Code Input
    comConfirmationCodeInput__labelText: "Полученный код",
    //Com Password
    comPassword__password__placeholder: "Пароль",
    comPassword__password__labelText: "Пожалуйста введите ваш пароль.",
    comPassword__passwordRepeat__placeholder: "Повторите пароль",
    comPassword__passwordRepeat__labelText: "Пожалуйста, подтвердите свой пароль.",
    //Com Admin Navigation
    comAdminNavigation__LocalizedNavigationDelimiter__control: "Управление",
    comAdminNavigation__RouterLink__organizationList: "Организации",
    comAdminNavigation__RouterLink__userList: "Пользователи",
    comAdminNavigation__LocalizedNavigationDelimiter__admin: "Кабинет администратора",
    comAdminNavigation__RouterLink__logout: "Выйти",
    comAdminNavigation__RouterLink__bookKeeperConnectionList: "Экземпляры биллинга",
    comAdminNavigation__RouterLink__organizationBookKeeperConnectionList: "Подключения биллинга",
    comAdminNavigation__RouterLink__uPanelApiConnectionList: "Кабинеты UPanel",
    comAdminNavigation__RouterLink__connectableServiceList: "Услуги",
    comAdminNavigation__LocalizedNavigationDelimiter__services: "Управление услугами",
    comAdminNavigation__LocalizedNavigationDelimiter__upanel: "Управление кабинетами",
    comAdminNavigation__LocalizedNavigationDelimiter__billings: "Управление биллингом",
    //Com Admin BookKeeper Connection
    adminBookKeeperConnectionAdd__title: "Новый экземпляр биллинга",
    adminBookKeeperConnectionAdd__description: "Добавление нового экземпляра биллинга",
    bookKeeperConnectionList__breadcrumbs: "Экземпляры",
    bookKeeperConnectionAdd__breadcrumbs: "Новый экземпляр",
    bookKeeperConnectionEdit__breadcrumbs: "Экземпляр",
    adminBookKeeperConnectionEdit__title: "Редактирование экземпляра биллинга",
    adminBookKeeperConnectionEdit__description: "Редактирование настроек соединения с экземпляром биллинга.",
    adminBookKeeperConnectionList__title: "Экземпляры биллинга",
    adminBookKeeperConnectionList__description: "Список всех созданных в системе соединений с биллингами.",
    //Com Admin Conn Serv
    adminConnectableServiceAdd__title: "Новая услуга",
    adminConnectableServiceAdd__description: "Добавление новой подключаемой услуги.",
    connectableServiceList__breadcrumbs: "Услуги",
    connectableServiceAdd__breadcrumbs: "Новая услуга",
    connectableServiceEdit__breadcrumbs: "Услуга",
    adminConnectableServiceEdit__title: "Редактирование услуги",
    adminConnectableServiceEdit__description: "Редактирование подключаемой услуги.",
    adminConnectableServiceList__title: "Список услуг",
    adminConnectableServiceList__description: "Список всех созданных в системе подключаемых услуг.",
    adminConnectableServiceList__available: "Доступна",
    adminConnectableServiceList__unavailable: "Недоступна",
    adminConnectableServiceList__visible: "Видима",
    adminConnectableServiceList__hidden: "Скрыта",
    //Com Admin OBKC
    adminOrganizationBookKeeperConnectionAdd__title: "Новое подключение организации",
    adminOrganizationBookKeeperConnectionAdd__description:
        "Добавление нового подключения организации к экземпляру биллинга.",
    organizationBookKeeperConnectionList__breadcrumbs: "Подключения",
    organizationBookKeeperConnectionAdd__breadcrumbs: "Новое подключение",
    organizationBookKeeperConnectionEdit__breadcrumbs: "Подключение",
    adminOrganizationBookKeeperConnectionEdit__title: "Редактировать подключение",
    adminOrganizationBookKeeperConnectionEdit__description: "Редактирование настроек подключения к биллингу.",
    adminOrganizationBookKeeperConnectionList__title: "Подключения к экземплярам биллинга",
    adminOrganizationBookKeeperConnectionList__description: "Список всех созданных в системе подключений к биллингу.",
    //Com Admin UPANEL API Conn
    adminUPanelApiConnectionAdd__title: "Новый кабинет UPanel",
    adminUPanelApiConnectionAdd__description: "Добавление нового подключения к кабинету UPanel.",
    uPanelApiConnectionList__breadcrumbs: "Кабинеты",
    uPanelApiConnectionAdd__breadcrumbs: "Новый кабинет",
    uPanelApiConnectionEdit__breadcrumbs: "Кабинет",
    adminUPanelApiConnectionEdit__title: "Редактирование кабинета UPanel",
    adminUPanelApiConnectionEdit__description: "Редактирование настроек подключения к кабинету UPanel.",
    adminUPanelApiConnectionList__title: "Кабинеты UPanel",
    adminUPanelApiConnectionList__description: "Список всех созданных в системе подключений к кабинетам.",
    //Com Admin Projects
    adminProjectList__breadcrumbs: "Проекты",
    adminProjectAdd__breadcrumbs: "Новый проект",
    adminProjectEdit__breadcrumbs: "Проект",
    adminProjectAdd__title: "Новый проект",
    adminProjectAdd__description: "Добавление нового проекта организации.",
    adminProjectEdit__title: "Редактирование проекта",
    adminProjectEdit__description: "Редактирование проекта выбранной организации.",
    adminProjectList__title: "Проекты организации",
    adminProjectList__description: "Список всех созданных в системе проектов организации.",
    //Com Admin Shell
    comAdminShell__title: "Безымянный",
    comAdminShell__description: "Администратор",
    //Com Org Navigation
    comOrgNavigation__LocalizedNavigationDelimiter__urProfile: "Юридическое лицо",
    comOrgNavigation__RouterLink__organization: "Общие сведения",
    comOrgNavigation__RouterLink__organizationDocuments: "Документы",
    comOrgNavigation__RouterLink__organizationSupport: "Поддержка",
    comOrgNavigation__LocalizedNavigationDelimiter__services: "Услуги",
    comOrgNavigation__LocalizedNavigationDelimiter__project: "Проекты",
    comOrgNavigation__RouterLink__projectList: "Проекты",
    comOrgNavigation__RouterLink__logout: "Выйти",
    comOrgNavigation__LocalizedNavigationDelimiter__organization: "Кабинет организации",
    comOrgNavigation__LocalizedNavigationDelimiter__buh: "Бухгалтерский учёт",
    comOrgNavigation__RouterLink__contractList: "Контракты",
    comOrgNavigation__RouterLink__accountList: "Счета",
    comOrgNavigation__RouterLink__serviceProvisionList: "Предоставление услуг",
    comOrgNavigation__RouterLink__serviceList: "Услуги",
    //Com Org Shell
    comOrgShell__description: "Юридическое лицо",
    //Com Client Navigation
    comClientNavigation__LocalizedNavigationDelimiter__ecoEntity: "Экономический субъект",
    comClientNavigation__RouterLink__contractList: "Контракты",
    comClientNavigation__RouterLink__accountList: "Счета",
    comClientNavigation__RouterLink__serviceProvisionList: "Предоставление услуг",
    comClientNavigation__LocalizedNavigationDelimiter__billing: "Биллинг",
    comClientNavigation__RouterLink__economicEntityList: "Контрагенты",
    comClientNavigation__RouterLink__serviceList: "Услуги",
    comClientNavigation__RouterLink__tariffList: "Тарифы",
    comClientNavigation__RouterLink__cabinet: "Кабинет UPanel",
    comClientNavigation__RouterLink__currencyList: "Валюты",
    comClientNavigation__LocalizedNavigationDelimiter__client: "Кабинет клиента",
    comClientNavigation__RouterLink__logout: "Выйти",
    //Com Project Shell
    comProjectShell__title: "Проект",
    //Com`User Navigation
    comUserNavigation__LocalizedNavigationDelimiter__fiz: "Физическое лицо",
    comUserNavigation__RouterLink__organizations: "Мои организации",
    comUserNavigation__RouterLink__documents: "Мои документы",
    comUserNavigation__RouterLink__support: "Поддержка",
    comUserNavigation__LocalizedNavigationDelimiter__buh: "Бухгалтерский учёт",
    comUserNavigation__RouterLink__userAccountingContractList: "Контракты",
    comUserNavigation__RouterLink__userAccountingAccountList: "Счета",
    comUserNavigation__RouterLink__userAccountingServiceProvisionList: "Предоставление услуг",
    comUserNavigation__RouterLink__userAccountingServiceList: "Услуги",
    comUserNavigation__LocalizedNavigationDelimiter__services: "Услуги",
    comUserNavigation__LocalizedNavigationDelimiter__cab: "Кабинет",
    comUserNavigation__RouterLink__logout: "Выйти",
    //Com User Shell
    comUserShell__title: "Безымянный",
    comUserShell__description: "Физическое лицо",
    //Com Support View
    comSupportView__more: "Загрузить ещё...",
    comSupportView__send: "Отправить сообщение",
    //Com Validated File Input
    comValidatedFileInput__existingFileUrl: "Посмотреть загруженный ранее файл",
    comValidatedFileInput__fileRemoveRequest: "Удалить загруженный ранее файл",
    comValidatedFileInput__isFileRemovalRequested: "Файл будет удалён после сохранения изменений.",
    //Error Messages
    error__isNotEmpty: "Обязательно для заполнения.",
    error__isNotEmpty__search: "Поисковый запрос не может быть пустым.",
    error__isNotEmpty__support: "Текст сообщения не может быть пустым.",
    error__isNotEmpty__services: "Поле не может быть пустым.",
    //ETC
    untitled: "Без названия",
    searchLoading: "Поиск...",
    loadLoading: "Загрузка...",
    fallback: "Пожалуйста, подождите...",
    undefined: "Здесь ничего нет",
    logout: "Выйти из системы",
    urProfile: "Юридическое лицо",
    fizProfile: "Физическое лицо",
    projectOrg: "Проект организации",
    unbroken: "Непрерывный",
    transactional: "Транзакционный",
    incoming: "Входящий",
    outgoing: "Исходящий",
    transfer: "Перевод",
    assignTitle: "Название",
    assignInfo: "Сведения",
    assignDiscard: "Отменить назначение",
    assignAssign: "Подтвердить и назначить",
};

//Component = com
//?.map(el =>  useLocalizedText({ id: el as LocaleKeys }, intl))
export default ru;
